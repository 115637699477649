import React from "react";

function Managekpidata() {
    return(
        <div className="Managekpidata">
            
        </div>
    )
    
}
export default Managekpidata;