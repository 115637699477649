import React from 'react'
import './style.css';
function Home() {
  return (
    <>
   
   
    <div className="container mt-2 gentium-book-plus-regular">
      <div className="row">
          <div className="col-md-12 text-center">
            <h1 className='gentium-book-plus-bold text-danger'>ORGANIZATION KPIs (DMR, MMR Tracking)</h1>

          </div>
        </div>
      <div className="row text-center ">
        <div className="col-sm-4">
            <div className="card bg-primary text-white">
              <div className="card-body title-head">Education</div>
            </div>
            <div className="card mt-1">
              <div className="card-body content">
                <h4 className="card-title">No. of Students</h4>
                <p className="card-text text-danger">(&#62; 3537)</p>
                
              </div>
            </div>

            <div className="card mt-1">
              <div className="card-body content">
                <h4 className="card-title">No. of Courses <b className='text-danger'>(12)</b> & Books <b className='text-danger'>(&#62; 40)</b></h4>
                
                
              </div>
            </div>
            <div className="card mt-1">
              <div className="card-body content">
                <h4 className="card-title">No. of 3P Assignments</h4>
                <p className="card-text text-danger">(&#62; 658)</p>
                
              </div>
            </div>
            <div className="card mt-1">
              <div className="card-body content">
                <h4 className="card-title">Revenue of Education</h4>
                <p className="card-text text-danger">(&#62; 1.6 Cr)</p>
                
              </div>
            </div>
            <div className="card mt-1">
              <div className="card-body">
                <h4 className="card-title">No. of Non Migrated Regional Employment Generated <b className='text-danger'>(&#62; 200)</b></h4>
                <span >(Direct & Indirect thru raised local skill level)</span>
                
              </div>
            </div>
        </div>
        <div className="col-sm-8">
          <div className="row">
            <div className="col-md-6">
              <div className="card bg-primary text-white">
                <div className="card-body title-head">Ecology</div>
              </div>
              <div className="card mt-1">
                <div className="card-body content">
                  <h4 className="card-title">No. of Native Trees</h4>
                  <p className="card-text text-danger">(&#62; 12000)</p>
                  
                </div>
              </div>

              <div className="card mt-1">
                <div className="card-body content">
                  <h4 className="card-title">No. of Ecology Visits & Footfalls</h4>
                  <p className="card-text text-danger">(&#62; 2000)</p>
                  
                </div>
              </div>
              <div className="card mt-1">
                <div className="card-body content">
                  <h4 className="card-title">No. of Ecology Products</h4>
                  <p className="card-text text-danger">(8)</p>
                  
                </div>
              </div>
              <div className="card mt-1">
                <div className="card-body content">
                  <h4 className="card-title">Revenue of Ecology</h4>
                  <p className="card-text text-danger">(&#60; 1.2 Lakh)</p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card bg-primary text-white">
                <div className="card-body title-head">Exprience</div>
              </div>
              <div className="card mt-1">
                    <div className="card-body content">
                      <h4 className="card-title">Diaspora Connect No. -FOA</h4>
                      <p className="card-text text-danger">(&#62; 2000)</p>
                      
                    </div>
                  </div>

                  <div className="card mt-1">
                    <div className="card-body content">
                      <h4 className="card-title">No. of Events & Attendance</h4>
                      <p className="card-text text-danger">(175)</p>
                      
                    </div>
                  </div>
                  <div className="card mt-1">
                    <div className="card-body content">
                      <h4 className="card-title">AIM Sessions beneficiary MHs</h4>
                      <p className="card-text text-danger">(300)</p>
                      
                    </div>
                  </div>
                  <div className="card mt-1">
                    <div className="card-body content">
                      <h4 className="card-title">Revenue of Experience</h4>
                      <p className="card-text text-danger">(&#62; 7000)</p>
                      
                    </div>
                  </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-1">
                  <div className="card-body content text-center">
                    <h4 className="card-title">Participation count & No. of native projects. Soldiers <b className='text-danger'>(&#60; 500)</b>, Commanders<b className='text-danger'>(&#62; 20)</b> & Protagonists <b className='text-danger'>(3)</b></h4>
                    
                    <span >(In issues that preserve native environment, heritage and identity)</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
          <div className="col-md-12">
            <div className="card mt-1">
                <div className="card-body content text-center">
                  <h4 className="card-title">No. of Urban, Rural & Integrated Pride Habitats <b className='text-danger'>(3)</b></h4>
                  
                  
                </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <b>Copyright@ Aranyak Valley</b>
          </div>
        </div>
    </div>


    </>
       

  )
}

export default Home