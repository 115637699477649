import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Managekpidata from './pages/Managekpidata';
import './App.css';

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path='/'  element={<Home />} />
          <Route path='/managekpidata'  element={<Managekpidata />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
